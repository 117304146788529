/* eslint-disable no-param-reassign */
import {
  computed, ComputedRef, reactive, watch,
} from 'vue';
import debounce from 'lodash.debounce';

export interface FetchManagerProps extends Record<string, unknown>{
  filters: Record<string, unknown>
  immediateFilters: string[]
  delayedFilters: string[]
  rows: unknown[]
  fetchContents(): Promise<any>
}

export interface FetchManager extends FetchManagerProps {
  fetching: boolean
  fetch(nextPage?: boolean): void
  showLoader: ComputedRef<boolean>
}

export default function useFetch(props: FetchManagerProps): FetchManager {
  const manager: FetchManager = Object.assign(reactive(props), {
    fetching: false,
    async fetch(nextPage = false) {
      manager.fetching = true;
      if (!nextPage) manager.rows = [];
      try {
        const data = await props.fetchContents();
        manager.rows.push(...data);
      } catch (e) {
        console.log(e);
      } finally {
        manager.fetching = false;
      }
    },
    showLoader: computed(() => manager.fetching || manager.rows.length !== 0),
  });

  const delayedFetch = debounce(() => {
    manager.rows = [];
    manager.fetch();
  }, 400);
  watch(
    () => manager.delayedFilters.map((key: string) => manager.filters[key]),
    () => {
      manager.rows = [];
      manager.fetching = true;
      delayedFetch();
    },
    { deep: true },
  );
  watch(
    () => manager.immediateFilters.map((key: string) => manager.filters[key]),
    () => {
      delayedFetch.cancel();
      manager.rows = [];
      manager.fetch();
    },
    { deep: true },
  );

  return manager;
}
