
import {
  defineComponent, reactive,
} from 'vue';
import Container from '@/components/Container.vue';
import ContainerHeader from '@/components/ContainerHeader.vue';
import ModalMallNew from '@/views/markets-and-malls/components/ModalMallNew.vue';
import useFetch from '@/modules/useFetch';
import api from '@/api';
import ViewportObserver from '@/components/ViewportObserver.vue';
import useUgns, { UgnsOption } from '@/modules/useUgns';
import { MallsRow } from '@/types/Markets';

interface MallsFilters extends Record<string, unknown>{
  mallName: string,
  ugnsId: UgnsOption,
}

export default defineComponent({
  name: 'MarketsAndMallsMallsIndex',
  components: {
    ViewportObserver,
    ModalMallNew,
    ContainerHeader,
    Container,
  },
  setup() {
    const modalMall = reactive({
      display: false,
    });
    const { ugns: ugnsSelector } = useUgns();
    ugnsSelector.fetch();

    const malls = useFetch({
      rows: [] as Record<string, unknown>[],
      filters: {
        ugnsId: ugnsSelector.selected,
        mallName: '',
      } as MallsFilters,
      immediateFilters: ['ugnsId'],
      delayedFilters: ['mallName'],
      async fetchContents() {
        const { ugnsId: ugns, mallName } = malls.filters as MallsFilters;
        const ugnsId = ugns?.id || 0;
        const params = {
          ...mallName && { mallName },
          ...ugnsId && { ugnsId },
          web: true,
        };
        return (await api.malls.findAll({ ...params })).data;
      },
    });

    malls.fetch();

    const cols = [
      {
        label: 'УГНС',
        display: (row: MallsRow) => row.ugnsTnoCode,
        style: 'vertical-align:top',
      },
      {
        label: 'Торговые центры',
        component: (row: MallsRow) => ({
          name: 'TOGroupedList',
          row: row.malls,
          field: 'mallName',
          tag: 'ol',
          link: 'markets-and-malls-malls-mall-id',
          getId: (entry: MallsRow['malls']) => ({ mallId: entry.id }),
        }),
      },
      {
        label: 'Кол-во этажей',
        component: (row: MallsRow) => ({
          name: 'TOGroupedList',
          row: row.malls,
          field: 'floors',
        }),
      }, {
        label: ' Кол-во НП',
        component: (row: MallsRow) => ({
          name: 'TOGroupedList',
          row: row.malls,
          field: 'taxpayers',
        }),
      },
    ];

    async function onCreateByFile() {
      await malls.fetch();
      modalMall.display = false;
    }

    return {
      cols,
      malls,
      modalMall,
      ugnsSelector,
      onCreateByFile,
    };
  },
});
