
import {
  defineComponent, reactive, watch,
} from 'vue';
import Modal from '@/components/UI/Modal/index.vue';
import useFilePreview from '@/modules/useFilePreview';
import api from '@/api';

export default defineComponent({
  components: {
    Modal,
  },
  emits: ['update:malls'],
  setup(props, ctx) {
    const filePreview = Object.assign(useFilePreview(), {
      display: false,
    });
    const newFile = reactive({
      loading: false,
      file: null as Blob | null,
      async submit() {
        newFile.loading = true;
        try {
          await api.malls.createByFile(newFile.file as File);
          alert('Загружено успешно');
          ctx.emit('update:malls');
        } catch (e: any) {
          alert(e?.response?.data || e?.message || 'Неизвестная ошибка на сервере');
        } finally {
          newFile.loading = false;
        }
      },
    });
    watch(
      () => [newFile.file],
      () => {
        if (!newFile.file) return;
        filePreview.decode(newFile.file);
      },
    );

    function onClickDownloadTpl() {
      return api.malls.downloadTemplate();
    }
    return {
      filePreview,
      newFile,
      onClickDownloadTpl,
    };
  },
});
