import { reactive } from 'vue';
import XLSX, { WorkSheet } from 'xlsx';

export default function useFilePreview() {
  return reactive({
    cols: null as {label: string, field: string}[] | null,
    rows: null as WorkSheet | null,
    decode(file: Blob) {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const data = e?.target?.result;
        if (data) {
          const workbook = XLSX.read(data, {
            type: 'binary',
          });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          // const workbookHeaders = XLSX.readFile(sheetName, { sheetRows: 1 });
          this.cols = (XLSX.utils.sheet_to_json(sheet, { header: 1 })[0] as string[]).map((label: string) => ({
            label, field: label,
          }));
          this.rows = XLSX.utils.sheet_to_json(sheet, { header: 0 });
        }
      };
      fileReader.readAsBinaryString(file as Blob);
    },
  });
}
