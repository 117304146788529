import { reactive } from 'vue';
import api from '@/api';

export interface UgnsOption {
  name: string | undefined,
  value: number | undefined,
  id: number | undefined,
}
export type ugnsParams = {
  withCA?: boolean
}
export default function useUgns(params?: ugnsParams) {
  const ugns = reactive({
    selected: {} as UgnsOption,
    options: [] as UgnsOption[],
    async fetch() {
      try {
        const resp = await api.ugnstno.findAll(params);
        ugns.options = [{ name: 'Все ТНО', id: 0, value: undefined }];
        ugns.options.push(...resp.data.map((item) => ({ name: item.ugnsTnoCode, value: item.id, id: item.id })));
      } catch (e) {
        console.log(e);
      }
    },
  });

  return { ugns };
}
